.leaflet-tooltip {
    color: white;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
}

.leaflet-popup-content-wrapper {
    background: #1E2D4E;

}

.leaflet-popup-content {
    background: #1E2D4E;

}

.leaflet-popup {
    transform: none;
    position: absolute !important;
    left: 20px;
    top: -400px !important;
}

.leaflet-tooltip {
    color: #1d2d4e;
}
